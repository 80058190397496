import React, { Component } from "react";
import "./custom.css";
import NotFound from "./notfound";

var baseurl = "https://api.pushpolitics.org/v1/";
class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template: "",
      norecord: false,
      https: false,
    };
  }
  componentDidMount() {
    const url = window.location.origin;
    if (!url.includes("localhost") && !url.includes("https")) {
      window.location = `https:${url.split(":")[1]}`;
    } else {
      let hostname = window.location.hostname;
      // hostname = 'testopalina41.pushpolitics.org';
      this.setState({ https: true }, () => {
        fetch(
          baseurl +
          "/GetTemplateBySubDomain?subdomain=" +
          hostname,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((response) => response.json())
          .then((responseJson) => {
            // console.log(responseJson);
            //   if(responseJson.code=="200"){
            this.setState({
              template: responseJson.data[0],
            });
            var css = responseJson.data[0]
              ? responseJson.data[0].style
                ? responseJson.data[0].style
                : ""
              : "",
              head = document.head || document.getElementsByTagName("head")[0],
              style = document.createElement("style");

            style.type = "text/css";
            if (style.styleSheet) {
              // This is required for IE8 and below.
              style.styleSheet.cssText = css;
            } else {
              style.appendChild(document.createTextNode(css));
            }

            head.appendChild(style);

            //set favicon based on domain name
            if (window.location.origin.indexOf("csaeconnect") != -1) {
              var link =
                document.querySelector("link[rel*='icon']") ||
                document.createElement("link");
              link.type = "image/x-icon";
              link.rel = "shortcut icon";
              link.href = "/favicon_csae.png";
              document.getElementsByTagName("head")[0].appendChild(link);
            }
            //  var head=document.getElementById('head')
            //  head.appendChild='<style>'+responseJson.data[0].style+'</style>';
            
            var title = document.getElementById("title");
            // title.innerHTML = responseJson.client_data
            //   ? responseJson.client_data.title
            //   : ""; comment for te widget title
            //preg match for campaign //
            var temp = this.state.template ? this.state.template.template : "";
            var patt1 = /pp_campaign campaign_id=[0-9]+/g;
            var result = temp.match(patt1);
            var patt2 = /[0-9]+/g;
            console.log(result);
            if (result) {
              result.map((j) => {
                var id = j.match(patt2);
                temp = temp.replace(
                  "[" + j + "]",
                  "<div class='pp_campaign' data-id='" +
                  id +
                  "'></div>"
                );
              });
            }
            if (temp) {
              document.getElementById("temp").innerHTML = temp;
              this.callEmbedWidgetFunction();
            } else {
              this.setState({
                norecord: true,
              });
            }
          });
      });
    }
  }
  callEmbedWidgetFunction = () => {
    setTimeout(() => {
      try {
        window.initWidget();
      } catch (error) {
        this.callEmbedWidgetFunction();
      }
    }, 1000);
  };
  render() {
    if (!this.state.https) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="pos_rel back_white">
          {this.state.template ? <div id="temp" /> : ""}
          {this.state.norecord ? <NotFound /> : ""}
        </div>
      </React.Fragment>
    );
  }
}

export default Template;
