import React from 'react';

const NotFound = () =>
  <div className="error_page">

    <div className="error_main_section">
    <div className="error_sideone">
        <img src="/img/pagenotfound.png" className="error_icon"/>
    </div>
    <div className="error_sidetwo">
        <h1>Opps's</h1>
        <h3>Page Not Found</h3>
        <p>We are sorry but the page you are looking for does not exist.</p>
      
    </div>
    </div>
  </div>

export default NotFound;